import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

// @ts-ignore
import Account from '@/Pages/Account/routes'
// @ts-ignore
import Builder from '@/Pages/Builder/routes'
// @ts-ignore
import UserDashboard from '@/Pages/Dashboard/routes'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: "*",
        name: '404',
        component: () => import('@/Layouts/404.vue')
    },
    UserDashboard,
    {
        path: '/',
        component: () => import('@/Layouts/FullPageSidebar.vue'),
        children: [
            { path: '/media', name: 'media', component: () => import('@/Pages/Media.vue') },
        ],
        meta: {
            requiresAuth: true,
        }
    },
    Builder,
    Account,
]

const router = new VueRouter({
  routes,
  mode: 'history',
})

export default router
