export default {
    path: '/manage-themes',
    component: () => import('@/Layouts/Default.vue'),
    children: [
        { path: '', name: 'builder.theme-categories', component: () => import('@/Pages/Builder/ThemesByCategories.vue') },
        { path: 'list/:categoryId', name: 'builder.theme-list', component: () => import('@/Pages/Builder/ThemesList.vue') },
        { path: 'builder/:themeId', name: 'builder', component: () => import('@/Pages/Builder/Builder.vue') },
        { path: 'builder', name: 'builder.create-theme', component: () => import('@/Pages/Builder/Builder.vue') },
        { path: 'categories', name: 'builder.categories', component: () => import('@/Pages/Categories/Categories.vue') },
        { path: 'tags', name: 'builder.tags', component: () => import('@/Pages/Tags/Tags.vue') },
        { path: 'social-link-providers', name: 'builder.social-link-providers', component: () => import('@/Pages/SocialLinkProviders/SocialLinkProviders.vue') },
        { path: 'embed-presets', name: 'builder.embed-presets', component: () => import('@/Pages/EmbedPresets/EmbedPresets.vue') },
    ],
    meta: {
        requiresAuth: true,
    },
};
