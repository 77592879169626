export default {
    'mins ahead': ' {n} mins ahead',
    'hours ahead': ' {n} hours ahead',
    'days ahead': '  {n} days ahead',
    'weeks ahead': ' {n} weeks  ahead',
    'months ahead': ' {n} months ahead',
    'in days': ' in  {n} days',
    'in weeks': ' in {n} weeks',
    'in months': ' in {n} months',
}
