export default {
    'Already have an account?': 'Уже есть аккаунт?',
    'Log in': 'Авторизоваться',
    Planned: "Записаны",
    Arrived: "Ожидают",
    arrived: "Ожидают",
    Appointments: "Записи",
    Board: "Доска",
    List: "Список",
    Materials: "Материалы",
    Store: "Склад",
    Calendar: "Календарь",
    Customers: "Клиенты",
    customers: 'Клиенты',
    Patients: "Пациенты",
    Patient: "Пациент",
    Customer: "Клиент",
    Custom: "Пользовательский",
    Products: "Продукты",
    Product: "Продукт",
    Categories: "Категории",
    Category: "Категория",
    'Category name': 'Название категории',
    'Ex. Consumables': 'Расходники',
    Brands: "Бренды",
    Brand: "Бренд",
    Suppliers: "Поставщики",
    Supplier: "Поставщик",
    Subject: 'Тема',
    Body: 'Сообщение',
    Company: "Компания",
    'Appointment time': 'Время встречи',
    'Appointment date': 'Дата встречи',
    'Make default': 'Сделать по умолчанию',
    'Appointment notification template': 'Шаблон уведомлений о встрече',
    'Debt return notification template': 'Шаблон уведомлений должников',
    Services: "Услуги",
    'Services & prices': 'Услуги и прайс',
    Profile: "Профиль",
    'My profile': "Мой профиль",
    profile: "Профиль",
    Team: "Команда",
    "ICD-10": "МКБ-10",
    Reports: "Отчеты",
    Summary: "Общие",
    Payments: "Оплаты",
    People: "Люди",
    'New customer': "Новый клиент",
    'New appointment': "Новая запись",
    Appointment: 'Запись',
    Confirmed: "Подтвержден",
    Late: "Опоздал",
    'Not confirmed': "Не подтвержден",
    confirmed: "Подтвержден",
    Completed: "Завершен",
    completed: "Завершен",
    Complete: "Завершить",
    'No show': "Не явился",
    'noshow': "Неявок",
    Today: 'Сегодня',
    Tomorrow: 'Завтра',
    'Next week': 'След. неделя',
    'Next month': 'След. месяц',
    Yesterday: 'Вчера',
    'This week': 'На этой неделе',
    'This month': 'В этом месяце',
    'Previous week': "Прошедшая неделя",
    'Previous month': "Прошлый месяц",
    'Custom range': "Указать период",
    'By date': "По дате",
    'Team member': "Сотрудник",
    'By guarantee': 'По гарантии',
    guarantee: 'Гарантия',
    cash: "Наличные",
    credit: "В долг",
    card: "По карте",
    other: "Другое",
    userBalance: 'Баланс клиента',
    Presets: 'Шаблоны',
    Preset: 'Шаблон',
    'New balance': 'Баланс после',
    'Debt info': 'Возврат долга',
    'Preset has been updated': 'Шаблон сохранен',
    'Preset has been created': 'Шаблон добавлен',
    'Preset has been applied': 'Шаблон применен',
    bank: "Банковский п-д",
    Address: "Адрес",
    "First name": "Имя",
    "Last name": "Фамилия",
    "Patient first name": "Имя пациента",
    "Patient last name": "Фамилия пациента",
    Details: "Детали",
    General: "Основные",
    Gender: "Пол",
    Male: "Мужчина",
    Female: "Женщина",
    male: "Мужчина",
    female: "Женщина",
    More: "Дополнительно",
    Birthdate: "Дата рождения",
    'Birth day': 'День рождения',
    Phone: 'Телефон',
    Complaints: 'Жалобы',
    Complaint: 'Жалоба',
    Objectively: "Объективно",
    Exam: "Осмотр",
    Exams: "Осмотры",
    'New exam': "Новый осмотр",
    'Customer card': 'Карточка клиента',
    'All exams history will be here': 'Вся история осмотров храниться в этом разделе',
    'Date created': 'Добавлен',
    'Customer passport': "Паспорт клиента",
    'Nothing yet': 'Пока ничего',
    Code: 'Код',
    Shortcode: 'Краткий  код',
    Description: 'Описание',
    Language: 'Язык',
    Label: "Наименование",
    manage: "Управление",
    'Complaints DB': 'Справочник жалоб',
    'Complaint has been saved': 'Жалоба сохранена',
    'Complaint has been created': 'Жалоба добавлена',
    'Objectively DB': 'Справочник объективно',
    References: "Справочники",
    Edit: "Редактировать",
    Timeline: "История",
    Notes: "Заметки",
    Note: "Заметка",
    Billing: "Оплаты",
    Storage: "Файлы",
    Search: "Поиск",
    Name: "Имя",
    Diagnosis: "Диагноз",
    Diagnoses: "Диагнозы",
    Treatment: "Лечение",
    Consumables: 'Расходники',
    Service: 'Услуга',
    Price: 'Цена',
    'Retail price': 'Розничная цена',
    'Retail price per': 'Розничная цена за',
    'Duration (Min)': 'Длительность (мин)',
    'Buffer time (Min)': 'Буферное время',
    'Ex.: A001': 'Напр.: A001',
    'Treatment name': 'Название процедуры',
    'Treatment duration': 'Длительность процедуры',
    'Time required to prepare to the next treatment': 'Время для подготовки к следующей процедуре',
    'Ex.: Primary consultation': 'Напр.: Первичная консультация специалиста',
    'Short description of treatment': 'Краткое описание процедуры',
    Cancel: 'Отмена',
    Save: 'Сохранить',
    Delete: 'Удалить',
    Ok: 'Готово',
    'Public treatment': "Доступна всем",
    'Public treatments will be published': "Процедура будет доступна для заказа черезе web-сайт и приложения",
    'Services & treatments': 'Услуги и процедуры',
    'Loading...': 'Загрузка...',
    Create: 'Создать',
    Duration: 'Длительность',
    'New treatment': 'Новая процедура',
    min: 'мин.',
    minutes: 'мин.',
    h: 'ч.',


    Upcoming: 'Запланировано',
    Previous: 'Прошедшие',
    All: 'Все',
    New: 'Новый',
    cancelled: 'Отменен',
    Started: 'На приеме',
    started: 'На приеме',
    'Total scheduled': 'Всего визитов',
    'Start at': 'Начало в',
    'Appointment at': 'Запись на',
    Status: 'Статус',
    Staff: 'Сотрудник',
    Active: 'Активный',
    Archived: 'Архив',
    Age: 'Возраст',
    Image: 'Фото',
    Date: 'Дата',
    'No confirmed appointments': 'Нет подтвержденных записей',
    'No waiting appointments': 'Нет ожидающих записей',
    'No started appointments': 'Нет начатых записей ',
    'In treatment': 'В процедуре',
    'Drag and drop to upload content!': 'Перетащите чтобы загрузить ',
    '...or click to select a file from your computer': '... или нажмите, чтобы найти на компьютере',
    'Ex.:John': 'Прим.:Валентин',
    'Ex.:Doe': 'Прим.:Бойко',
    'Select date': 'Выбрать дату',
    'Set photo': 'Указать фото',
    'Remove image': 'Удалить фото',
    'Blacklist customer': 'В черный список',
    Blacklisted: 'В черном списке',
    'Blacklisted users can not make new appointment. But administrator still can make an appointment.': 'Добавьте в черный список сомнительных или клиентов часто пропускающих процедуры',
    'Enable general SMS notifications': 'Включить общие СМС уведомления',
    'If active, the customer agreed to receive general SMS messages': 'Клиент будет получать уведомления о записях',
    'Enable promo SMS notifications': 'Включить промо нотификации',
    'If active, the customer agreed to receive promo SMS messages': 'Клиент будет получать сообщения о промо акциях и других событиях',
    Add: 'Добавить',
    'Add supply': 'Добавить поставку',
    'Add product': 'Добавить продукт',
    'Add category': 'Добавить категорию',
    'Add brand': 'Добавить бренд',
    'Add supplier': 'Добавить поставщика',
    'Ex.: Caries': 'Прим.: Кариес',
    Select: 'Выбрать',
    'Select diagnosis': 'Выбрать диагноз',
    'Preliminary diagnosis': 'Предварительный диагноз',
    'Final diagnosis': 'Окончательный диагноз',
    'Select from templates': 'Выбрать из шаблонов',
    'Save as template': 'Сохранить как шаблон',
    Use: 'Использовать',
    'Service name': 'Название сервиса',
    'Ex.: Consultation': 'Прим.: Консультация',
    'Describe service': 'Опишите сервис',
    'Public section': 'Общедоступный сервис',
    'Public sections will be published.': 'Общедоступные сервисы будут опубликованы на сайте и в приложениях',
    Rank: 'Ранк',
    'Ex.: 0-10': 'Прим.:0-10',
    Visits: 'Посещения',
    'No shows': 'Неявок',
    Options: 'Опции',
    Objectivly: 'Объективно',
    'Are you sure?': 'Вы уверены?',
    'Do you really want to delete the record ?': 'Вы подтверждаете удаление записи?',
    Specialty: 'Специальность',
    'Ex.: Therapist': 'Прим.: Терапевт',
    'Ex.: therapist': 'Прим.: therapist',
    Map: 'Карта',
    Missing: 'Отсутсвует',
    Root: 'Корень',
    Caries: 'Кариес',
    Pulpitis: 'Пульпит',
    Periodontitis: 'Периодонтит',
    Plombed: 'Пломба',
    Parodontoz: 'Пародонтоз',
    Shakiness: 'Подвижность',
    Crown: 'Коронка',
    Intact: 'Интактный',
    Depulped: 'Депульпированный',
    'Primary adentia': 'Первичная адентия',
    'Secondary adentia': 'Вторичная адентия',

    Implant: 'Имплант',
    'Cement caries': 'Кариес цемента',
    'Ortopedical status': 'Ортопедический',
    'Therapeutic status': 'Терапевтический',
    'Anchor pin': 'Анкерный штифт',
    Vinir: 'Винир',
    Retreat: 'Перелечить',
    Exclude: 'Удалить',
    Inset: 'Вкладка',
    Facet: 'Фасетка',
    'Parodontal status': 'Состояние пародонта',
    'Treatment required': 'Нуждается в лечении',
    'Treatment not required': 'Не нуждается в лечении',
    'Ex. Apple': 'Прим. Apple',
    Bundles: 'Пакеты',
    Bundle: 'Пакет',
    'Exam created': 'Новое обследование',


    Featured: 'В избранные',
    'Treatment plan': 'План лечения',
    'Select treatment': 'Выбрать лечение',
    'Add treatment': 'Добавить лечение',
    'Tooth #': 'Зуб #',
    'Total': 'Всего',
    'Root canals': 'Каналы',
    'Time': 'Время',
    'mSv': 'мкЗв',
    'No channel measurement': 'Каналы зубов еще не измерялись',
    'Includes services or products': 'Включает сервисы или продукты',
    'Print': 'Печать',
    Contract: 'Договор',
    Others: 'Другие',
    Agreement: 'Соглашение',
    'Add item': 'Дополнительные пункты',
    'No slots': 'Нет слотов',
    Dates: 'Даты',
    Intraoral: 'Интраоральный',
    intraoral: 'Интраоральный',
    Orthopantomogram: 'Ортопантомограмма',
    orthopantomogram: 'Ортопантомограмма',
    'CT scan': 'КТ',
    'cT scan': 'КТ',

    'Client application': 'TR-Client application',
    'Company code': 'Код по ОКПО',
    'Add service': 'Добавить сервис',
    'Add another appointment': 'Добавить новую дату',
    'service time': 'сервисное время',
    Xrays: 'Рентген',
    Xray: 'Рентген',
    'New xray': 'Новый рентген',
    Dose: 'Доза',
    Quantity: 'Количество',
    Type: 'Тип',
    'Record created': 'Запись добавлена',
    'Record updated': 'Запись обновлена',
    'Xray created': 'Добавлен рентген',
    'Invoice created': 'Выставлен инвойс',
    'Ex.: Lc': 'Напр.: Lc',
    'Health card': 'Карта здоровья',
    Stock: 'Склад',
    Supply: 'Поставка',
    Supplies: 'Поставки',
    Pricing: 'Цены',
    'Supply price': 'Цена поставки',
    'Enable product pricing': 'Управление ценой',
    'Enable stock management': 'Управление складом',
    'Enable supply management': 'Управление поставками',
    'Product name': 'Наименование продукта',
    'Ex.:Medical glove': 'Напр.:Перчатки медицинские',
    'Barcode': 'Баркод',
    'SKU': 'Артикул',
    'Ex.:12345678': 'Напр.:12345678',
    'Ex.:AC12345': 'Напр.:AC12345',
    'Supply date': 'Дата поставки',
    'Expire date': 'Годен до',
    'Use current supply': 'Расходовать из этой поставки',
    'Supply quantity': 'Кол-во в поставке',
    'The supply will be forced to use in checkout and reports': 'Поставка будет использована как текущая, расходы будут списаны из этой поставки.',
    'Required minimum at store': 'Минимальное кол-во',
    'Measurement': 'Ед. Измерения',
    'Supplier details': 'Детали поставщика',
    'Supplier name': 'Имя поставщика',
    'Ex. Main supplier': 'Напр.:Основной поставщик',
    'Supplier notes': 'Заметки',
    'Ex.: Call after 10 AM': 'Напр.:Звонить после 10 утра',
    'Contacts': 'Контакты',
    'Contact name': 'Контактное лицо',
    'Ex. John Park': 'Напр.: Андрей Дибров',
    'Primary phone': 'Основной номер',
    'Secondary phone': 'Дополнительный',
    'Security': "Безопасность",
    'Schedule': "График",
    'Website': 'Вебсайт',
    'Street': 'Улица',
    'City': 'Город',
    'State': 'Район',
    'ZIP / Post code': 'Почтовый индекс',
    'Country': 'Страна',
    'Ex.: Tashkent': 'Напр. Ташкент',
    'Billing details': 'Данные биллинга',
    'Legal information': 'Юридические данные',
    'Legal information provided in this section will be used for all your invoices and other legal documents': 'Данные указанные в этом разделе будут использованы в юридических документах  а также счетах',
    'Legal name': 'Юридическое имя',
    'Enter notes for all invoices': 'Заметка для инвойсов',
    'Company timezone': 'Временная зона',
    'It is important to select correct timezone.': 'Важно выбрать правильную временную зону',
    'Interface default language': 'Язык по-умолчанию системы',
    'Users can change in their profile.': 'Каждый пользователь системы могут сменить в настройках',
    'Currency': 'Валюта',
    'For all services calculations': 'Используется для всех  рассчетов',
    'Contact information': 'Контактная информация',
    'Social networks': 'Социальные сети',

    'Ml': 'Мл.',
    'ml': 'мл.',
    'Mg': 'Мг.',
    'mg': 'мг.',
    'Sm': 'См.',
    'sm': 'см.',
    'G': 'Грамм',
    'g': 'г.',
    'см': 'см.',
    'Units': 'Шт.',
    'units': 'шт.',
    Pack: 'Упаковка',
    'pack': 'Уп.',

    'Ex.:100': 'Напр.:100',
    'Minimum': 'Минимум',
    'Per serving': 'Порция',
    'To buy': 'Закуп',
    'product': {
        'lowStock': '<b>{product.label}</b> заканчивается на складе'
    },
    'No new notifications': 'Нет новых уведомлений',
    'In waiting list': 'В лист ожидания',
    'Waitlist': 'Лист ожидания',
    'In buy list': 'В списке покупок',
    'Ex.: Agreement': 'Прим.:Договор',
    'Documents': 'Документы',
    'Сompany': 'Компания',
    'Title': 'Заголовок',
    'Content': 'Содержимое',
    'Source': 'Источник',
    'Sources': 'Источники',
    'Campaign': 'Кампания',
    'Referer': 'Пришел от:',
    'Requires value': 'Требуется значение',
    'Media': 'Фото',
    'Media manager': 'Управление медиа',
    'Treatments': 'Лечение',
    'Take a photo': 'Сделать фото',
    'Select Device': 'Выбрать устройство',
    'Logout': 'Выход',
    'Leave a comment': 'Оставить заметку',
    'Save note': 'Сохранить заметку',
    'Password': 'Пароль',
    'Current password': 'Текущий пароль',
    'New password': 'Новый пароль',
    'Confirm new password': 'Подвердите пароль',
    'Update password': 'Обновить пароль',
    'Password suggestions': 'Предложения по паролю',
    'To create a new strong password, you have to meet all of the following requirements:': 'Безопасный пароль должен соответсвовать следующим критериям:',
    'Minimum 8 character': 'Минимум 8 символов',
    'At least one special character (#%.,)': 'Минимум один спец символ  (#%.,)',
    'At least one number': 'Хотябы одна цифра',
    'Can’t be the same as a previous password': 'Не использовался ранее',

    'Update schedule': 'Обновить расписание',
    Monday: 'Понедельник',
    Tuesday: 'Вторник',
    Wednesday: 'Среда',
    Thursday: 'Четверг',
    Friday: 'Пятница',
    Saturday: 'Суббота',
    Sunday: 'Воскресенье',
    Notifications: 'Уведомления',
    'View all': 'Посмотреть все',
    'Search client': 'Поиск клиента',
    'Appointment schedule': 'Новая запись',
    Repeat: 'Повтор',
    Frequency: 'Частота',
    Ends: 'Заканчивается',
    'After {value} times': 'После {value}х раз',
    Repeats: 'Повторяется',
    'Every {value} days': 'Каждые {value} дня',
    'Every 5 days': 'Каждые 5 дней',
    Weekly: 'Раз в неделю',
    'Every {value} weeks': 'Каждые {value}  недели',
    Monthly: 'Раз в месяц',
    'Every {value} months': 'Каждые {value} месяцев',
    'Every 2 months': 'Каждые 2 месяца',
    'Every 3 months': 'Каждые 3 месяца',
    'Every 4 months': 'Каждые 4 месяца',
    'Month': 'Месяц',
    Yearly: 'Раз в год',
    'Do not repeat': 'Не повторять',
    Daily: 'Ежедневно',
    'Specific date': 'Дата',
    Ongoing: 'Все время',
    'View Appointment': 'Просмотр записи',
    Close: 'Закрыть',
    Checkout: 'Счет',
    'Payment method': 'Метод оплаты',
    'Payment amount': 'Сумма оплаты',
    'Amount': 'Сумма',
    'Balance': 'Баланс',
    'Payment successful': 'Оплата успешна',
    'Cancel appointment?': 'Отменить запись?',
    'Cancel appointment': 'Отменить запись',
    Socials: 'Социальные',
    Settings: 'Настройки',
    Builder: 'Конфигуратор',
    'General Details': 'Основные данные',
    'Company name': 'Имя компании',
    'Your company brand/known name.': 'Название вашего бренда/компании',
    'Ex.:ProfiDental': 'Напр.: ПрофиДентал',
    'Company description': 'Описание компании',
    'Describe your company in few words so no one wants to read long text.': 'Несколько слов о вашей компании',
    'Ex.: We are experts in dental care': 'Прим.: Мы эксперты в области зубной защиты',
    'Public company': 'Публичная компания',
    'Making your profile public means that anyone on the 32Desk network will be able to find you': 'Публичные компании показываются в каталогах 32Desk',
    'No payments yet': 'Пока нет оплат',
    'Access': 'Доступ',
    'Payment methods': 'Методы оплат',
    'Cash': 'Наличные',
    'Bank': 'Банковский перевод',
    'Card': 'По карте',
    'Credit': 'В долг',
    'Other': 'Другое',
    'Invoice': 'Счет',
    'Invoice #': 'Счет #',
    'Invoiced from': 'Счет выставлен от',
    'Invoiced ID': 'Счет Номер',
    'Invoiced to': 'Счет для',
    'Cost': 'Стоимость',
    'from': 'от',
    'Total amount due': 'Итого к оплате',
    'Set password': 'Задать новый пароль',
    'Username': 'Имя пользователя',
    'Role': 'Роль',
    Doctor: 'Доктор',
    Administrator: 'Администратор',
    Director: 'Директор',
    'Finance manager': 'Финансовый менеджер',
    'Item has been saved': 'Запись сохранена',
    'Item has been created': 'Запись добавлена',
    'Hide past': 'Скрыть прошедшие',
    Color: 'Цвет',
    Manipulations: 'Манипуляции',
    Manipulation: 'Манипуляция',
    'Ex.: Anesthesia': 'Напр.: Анестезия',
    'Select manipulations': 'Выбрать манипуляции',
    'Add manipulation': 'Добавить манипуляцию',
    'Please select a customer': 'Выберите пользователя',
    'Week': 'Неделя',
    'Day': 'День',
    'Days': 'Дни',
    'Hours': 'Часы',
    'Template': 'Шаблон',
    'Time templates': 'Шаблоны времени',
    'New template': 'Новый шаблон',
    'Add time template': 'Добавить шаблон времени',
    'Minutes': 'Минуты',
    '3 Days': '3 Дня',
    'mins ahead': ' | за минуту | за {n} минуты | за {n} минут',
    'hours ahead': ' | за {n} час | за {n} часа | за {n} часов',
    'days ahead': ' | за {n} день | за {n} дня | за {n} дней',
    'weeks ahead': ' | за {n} неделю | за {n} недели | за {n} недель',
    'months ahead': ' | за {n} месяц | за {n} месяца | за {n} месяцев',
    'in days': ' | через {n} день | через {n} дня | через {n} дней',
    'in weeks': ' | через {n} неделю | через {n} недели | через {n} недель',
    'in months': ' | через {n} месяц | через {n} месяца | через {n} месяцев',
    'Notify client': 'Уведомить клиента',
    'Note created': 'Заметка создана',
    Browse: 'Выбрать из списка',
    'Please select final diagnonsis': 'Выберите окончательный диагноз',
    'Sign in': 'Вход',
    'Access to your dashboard': 'Доступ в систему управления',
    'Forgot password?': 'Забыли пароль?',
    'Enter your password': 'Пароль вашего аккаунта',
    'Logging out': 'Выход',
    'See you soon': 'Ждем вас еще',
    'Done': 'Сделано',
    'User Activities': 'Лог действий',
    'Value': 'Значение',
    // 'N/A': '-',
    'Select diagnosis from ICD-10 list': 'Выберите диагноз из МКБ-10',
    'To Be Done': 'Нужно сделать',
    Action: 'Действие',
    'Add payment': 'Добавить оплату',
    'Cancellation reason. Ex.: Wrong payment': 'Причина отмены Прим. Ошибочная оплата',
    'Ex.: Treatment of caries': 'Прим. Лечение простого кариеса',
    'Payment cancellation': 'Отмена оплаты',
    'Payment cancelled': 'Оплата отменена',
    'Cancelled': 'Отменен',
    'Total paid': 'Всего оплачено',
    'Approved': 'Подтвержден',
    'Explanation': 'Описание',
    'User Balance': 'Баланс пользователя',
    'Debtors': 'Должники',
    'Outdated': 'Просрочено',
    'Tags': 'Тэги',
    'Payment status': 'Статус оплаты',
    'Payment from balance': 'Оплата со счета',
    'Debt details': 'Детали долга',
    'Total Payment': 'Всего к оплате',
    'Paid amount': 'Оплачено',
    'By gender': 'По полу',
    Income: 'Приход',
    'Invalid login': 'Неверный логин или пароль',
    'Average Bill': 'Средний счет',
    'Missing credentials': 'Введите логин и пароль',
    'Team member updated': 'Пользователь обновлен',
    Specialties: 'Специальности',
    'Static price': 'Фиксированная цена',
    'Static duration': 'Фиксированная длительность',
    'Allow log in': 'Разрешить авторизацию',
    'User balance': 'Баланс пользователя',
    'Your access has been restricted. Please contact your administrator to resolve this issue.': 'Ваш доступ был ограничен. Пожалуйства свежитесь с вашим администратором.',
    Roles: 'Роли',
    'Balance top up': 'Пополнение баланса',
    Resource: 'Ресурс',
    Permissions: 'Права доступа',
    'Paid partially': 'Оплачен не полностью',
    'Paid': 'Оплачен',
    'New role': 'Новая роль',
    View: 'Просматривать',
    'Edit role': 'Изменить роль',
    'new': 'Новый',
    'By payment type': 'По методу оплаты',
    'By team members': 'По членам команды',
    'By age': 'По возрасту',
    'Ex.:Sales manager': 'Прим. Менеджер по продажам',
    'Ex.:Can approve payments and manage billing': 'Прим. Может управлять финансами и складом',
    'my-team': 'Команда',
    'By day': 'По дням',
    'By status': 'По статусу',
    'Debt and prepaid': 'Долги и предоплаты',
    'my-company': 'Компания',
    'permissions': 'Права',
    'Most valuable team member': 'Наиболее ценный член команды',
    'Least valuable team member': 'Наименее ценный член команды',
    'Most valuable patient': 'Наиболее ценный пациент',
    'Least valuable patient': 'Наименее ценный пациент',
    'Most debt patient': 'Пациент с наибольшим долгом',
    'Most prepaid patient': 'Пациент с наибольшей предполатой',
    'Toppers': 'Топы',
    'Most selling product': 'Наиболее продаваемый продукт',
    'Least selling product': 'Наименее продаваемый продукт',
    'Most used product': 'Наиболее используемый продукт',
    'Most used service': 'Наиболее используемый сервис',
    'Most selling service': 'Наиболее продаваемый сервис',
    'Least used product': 'Наименее используемый продукт',
    'Least selling service': 'Наименее продаваемый сервис',
    'Least used service': 'Наименее используемый сервис',
    'roles': 'Роли',
    'users': 'Пользователи',
    'Add new role': 'Добавить роль',
    'Actions': 'Действия',
    'See all': 'Смотреть все',
    'Changes to roles saved': 'Изменения в ролях обновлены',
    'Appointment created': 'Запись создана',
    'Treatment presets': 'Шаблоны лечений',
    'SMS template': 'Шаблон СМС сообщения',
    'Explanation of debt': 'Заметка',
    'Discount': 'Скидка',
    'Ex.: VIP': 'При.: VIP клиен',
    'Exam on': 'Осмотр от',
    'Done on': 'Сделано',
    'Per page': 'На страницу',
    'Pre-treatment status': 'Состояние зуба',
    'Teeth and mouth condition': 'Состояние зуба и полости рта',
    'Condition': 'Состояние',
    'Passport number': 'Номер паспорта',
    'Passport given by': 'Паспорт выдан',
    'Patient passport number': 'Номер паспорта пациента',
    'Patient age': 'Возраст пациента',
    'Patient gender': 'Пол пациента',
    'Patient diagnosis': 'Диагноз пациента',
    'Patient address': 'Адресс пациента',
    'Patient birth date': 'Дата рождения пациента',
    Earnings: 'Наработано',
    Revenue: 'Заработок',
    Payed: 'Оплачено',
    'Payed out': 'Выплачено',
    'Confirm': 'Подтвердить',
    Earned: 'Заработано',
    'Left over': 'Чистая прибыль',
    'Doctor first name': 'Имя доктора',
    'Doctor last name': 'Фамилия доктора',
    'Patient passport given by': 'Паспорт пацинета выдан',
    Prepaid: 'Предоплата',
    Channel: 'Канал',
    'Transaction ID': '№ Транзакции',
    'Tooth does not have any exams yet': 'Зуб еще не осматривался',
    'Start examing tooth by clicking "New exam"': 'Начните первый осмотр зуба прямо сейчас',
    'Conclusion': 'Заключение',
    'Application form': 'Анкета 043',
    Sum: 'Сумма',
    Debt: 'Долг',
    'Emergency logout': 'Экстренный выход',
    'Are you sure you want to logout all of your team?': 'Вы уверенны что хотить разлогинить всех в вашей команде?',
    perm: {
        'appointment': 'Записи',
        'customers': 'Клиенты',
        'my-company': 'Моя компания',
        'my-team': 'Моя команда',
        'cart': 'Корзина',
        'company': 'Компания',
        'profile': 'Профиль',
        'presets': 'Шаблоны лечения',
        'payments': 'Оплаты',
        'tags': 'Тэги',
        'communications': 'Связи с клиентом',
        'notes': 'Заметки',
        'xrays': 'Рентген',
        'products': 'Продукты и Склад',
        'reports': 'Отчеты',
        'notifications': 'Уведомления',
        'roles': 'Роли и права доступа',
        'treatment': 'Процедуры',
        'icd': 'МКБ-10',
        'odb': 'Объективно',
        'service': 'Услуги',
        'timeline': 'Лог действий',
        'bundle': 'Настройки',
        'time-templates': 'Шаблоны времени уведомлений',
        'force-logout': 'Экстренный выход',
        'tasks': 'Задачи',
        'work-place': 'Рабочие места',
        'service-reports': 'Отчеты по услугам',
        'product-reports': 'Отчеты по продуктам',
        'sales-reports': 'Отчеты по врачам',
        'customer-reports': 'Отчеты по врачам',
        'configurator': 'Конфигуратор',
        'treatment-plan': 'План лечения',
        'media-summary': 'Отчеты по файлам',
        'labs': 'Лабаротория',
        'expense': 'Расходы',
        'toppers': 'Топы',
        'balance-reports': 'Отчеты по предполатам/долгам',
        'payment-reports': 'Отчеты по методам оплат',
        'appointment-reports': 'Отчеты по записям',
        'suppliers': 'Поставщики',
        'supplies': 'Поставки',
        'categories': 'Категории',
        'brands': 'Бренды',
        'sales-summary': 'Общие отчеты',
        'invoices-summary': 'Отчет по счетам',
        'sources': 'Источники',
        'specialty': 'Специальности',
        'cdb': 'Жалобы',
        'notification': 'Уведомления',
        'my-notifications': 'Уведомления',
        'diagnose-objectively': 'Окончательный диагноз',
        'fields': 'Карта здоровья',
        'exam': 'Осмотры',
        'manipulations': 'Манипуляции',
        'users': 'Пользователи',
        'permissions': 'Права доступа',
        'tooth': 'Зубы',
        'invoices': 'Счета',
        'transactions': 'Оплаты',
        'documents': 'Документы',
        'sms': 'СМС'
    },
    'Pay another': 'Оплатить еще',
    'Back': 'Назад',
    'Pay': 'Оплатить',
    'Payment': 'Оплата',
    'Pay back': 'Сдачи',
    'Ex.: Pain': 'Прим. Боль',
    'Invoice closed': 'Счет закрыт',
    'Baby map': 'Детская карта',
    'Treatment price': 'Цена процедуры',
    'My 32Desk': 'Мой 32Desk',

    'Current and previous illnes': 'Пересенные и сопутсвующие заболевания',
    'Profession': 'Профессия',
    'X-ray examinations history': 'История рентгенологических обследований',
    'Document generated by': 'Сгенерировано при помощи',
    'Manipulations made': 'Проделанные манипуляции',
    'Products used': 'Было использовано',
    'Ministry of Healthcare': 'Министрерство здравоохранения',
    'ProfileFormBy': 'Медицинская документация форма № 043/У    <br />Утверждена Уз.РССВ 10.05.2010',
    'Medical card of dental patient': 'Медицинская карта стоматологического больного',
    'Fullname': 'Фамилия, Имя, Отчество',

    'Recommendations made': 'Даны рекомендации',
    'Recommendations': 'Рекомендации',
    'Ex. rinse mouth twice a day': 'Прим. Полоскать рот два раза в день',
    'Initial examination': 'Первичный осмотр',
    'Bite': 'Прикус',
    'Overjet': 'Глубокий',
    'Open bite': 'Открытый',
    'Crossbite': 'Перекресный',
    'Overbite': 'Дистальный (прогнатический)',
    'Underbite': 'Мезиальный (прогенический)',
    'Orthognathic': 'Ортогнатический',
    'Progenic': 'Прогенический',
    'Straight': 'Прямой',
    'Biprognatic': 'Бипрогнатический',
    'State of oral mucosa': 'Состояние слизистой  оболочки полости рта',
    'X-Ray and Lab examination results': 'Данные рентгеновских и лабораторных исследований',
    'Normal': 'Хороший',
    'Satisfactory': 'Удовлетворительный',
    'Unsatisfactory': 'Неудовлетворительный',
    'Bad': 'Плохой',
    'Very Bad': 'Очень плохой',
    'Accept appointments': 'Принимает записи',
    'Percent from total revenue': 'Базовый процент от услуг',
    'By Staff': 'По сотрудникам',
    'Scheduled for today': 'Записаны на сегодня',
    'Oral Hygiene Index': 'Индекс Гигиены по Федорову-Володкиной',
    'Unknown': 'Неизвестный',
    'POS': 'Точка продаж',
    'POS terminal': 'Терминал продаж',
    'Duplicate to tooth': 'Скопировать на зуб',
    'All done': 'Все сделано',
    'Apply': 'Применить',
    'All top': 'Все верхние',
    'All bottom': 'Все нижние',
    'Clear': 'Очистить',
    'By tooth': 'По зубам',
    'Remove': 'Удалить',
    'Appointment has been rescheduled': 'Запись изменена',
    'Appointment has been created': 'Запись создана',
    'Appointment has been saved': 'Запись сохранена',
    'Do you confirm ?': 'Вы подтверждаете ?',
    'By source': 'По источнику',
    'Total earned': 'Выполнено на сумму',
    'Total debt for end of period': 'Общий долг на конец периода',
    'Debt for period of time': 'Долгов за этот период',
    'No treatment templates': 'Нет шаблонов лечения',
    'Try creating first by saving your current exam': 'Сохраните протоколы лечения в ваши шаблоны, чтобы не повторять действия',
    'In debt': 'В долг',
    'Edit/Reschedule appointment': 'Изменение записи',
    Yes: 'Да',
    No: 'Нет',
    'Name Last name or Phone': 'Имя Фамилия или Телефон',
    'Phone number protect': 'Защита номеров телефонов',
    'Do not show customer phone numbers if user does not have a seeAll permission': 'Не показывать номер телефона клиента, если у пользователя нет разрешения',
    'Stock leftover': 'Остаток на складе',
    'Initial quantity': 'Изначальное количество',
    'Total revenue': 'Прибыль сотрудников',
    'Other ways of examination': 'Данные дополнительного обследования',
    'Objectivly details': 'Данные объективного осмотра',
    Diary: 'Дневник',
    'Remind after': 'Напомнить через',
    'Tasks': 'Задачи',
    'Reminder': 'Напоминание',
    'Start date': 'Начало выполнения',
    'Due date': 'Срок выполнения',
    'Select team member': 'Выберите члена команды',
    'Select service': 'Выберите услугу',
    'Remind about a treatment': 'Напомнить о процедуре',
    'Remind about': 'Напомнить о',
    'End before': 'Закончить до',
    'Start': 'Начать',
    'Select a treatment to be repeated': 'Выберите процедуру которую хотели бы повторить',
    'Edit/Reschedule task': 'Изминеть/Перенести задачу',
    'Create appointment': 'Создать встречу',
    'Treatment received': 'Полученная услуга',
    'Upcoming treatment': 'Предстоящяя услуга',
    'Do not call after 8 p.m.': 'Не звонить после 8 вечера',
    'Trash': 'Корзина',
    'New task created': 'Новая задача создана',
    'My templates': 'Мои шаблоны',
    'Recently used': 'Недавние',
    'Deleted at': 'Удален в',
    'Do you confirm': 'Вы подтвреждаете',
    'This operation is permanent and can not be reversed.': 'Данная операция не обратима.',
    'Permanently delete': 'Удалить навсегда',
    'Recover': 'Восстановить',
    'Recently deleted': 'Недавно удаленные',
    'Successfully recovered': 'Успешно востановлен',
    'Permanently deleted': 'Безвозвратно удален',
    'Item has been added': 'Услуга добавлена',
    'Duration step': 'Шаг длительности',
    'Calendar step': 'Шаг в календаре',
    'Appointment hours step': 'Шаг визитов',
    'Communications': 'Связи',
    'Incoming': 'Входящий',
    'Outgoing': 'Исходящий',
    'Outgoing call': 'Исходящий звонок',
    'Incoming call': 'Входящий звонок',
    'Write SMS': 'Написать СМС',
    'Write Email': 'Написать email',
    'Payouts': 'Выплаты',
    'Changes saved': 'Изменения сохранены',
    'Error while saving': 'Ошибка при сохранении',
    'Invoice prefix': 'Префикс для инвойсов',
    'Invoice counter': 'Счетчик инвойсов',
    'View sample': 'Просмотреть пример',
    'Status localis': 'Развитие настоящего заболевания',
    'Objectivly checks': 'Объективно',
    'Exam has been created': 'Осмотр создан',
    'Exam has been saved': 'Осмотр сохранен',
    'Product has been saved': 'Продукт сохранен',
    'Product has been created': 'Продукт создан',
    'Roots has been saved': 'Данные сохранены',
    'Branch': 'Филиал',
    'Branches': 'Филиалы',
    'Branches can see all customers': 'Филиалы могут видеть всех клиентов',
    'Common customers, team and reports with branches': 'Общие клиенты, команда и отчеты с филиалами',
    'Select branch': 'Выберите филиал',
    'Start of the day': 'Сумма остатка с пред. рабочего дня',
    'End of the day': 'Сумма остатка на конец рабочего дня',
    'Date of operations': 'Дата кассовых операций',
    'Do you want to checkout?': 'Хотите выписать счет?',
    'Product has been added': 'Продукт добавлен',
    'Clear selection': 'Очистить выбор',
    'Grouped': 'Группировать',
    'Select tooth': 'Выбрать зубы',
    'Work places': 'Рабочие места',
    'Work place': 'Рабочее место',
    'By workplaces': 'По рабочим местам',
    'New work place': 'Новое рабочее место',
    'Work place has been saved': 'Рабочее место было сохранено',
    'Work place has been removed': 'Рабочее место удалено',
    'Teeth not selected': 'Зубы не выбраны',
    'Work order': 'Наряд',
    'Cart has been save': 'Корзина была сохранена',
    'Cart has been removed': 'Корзина была удалена',
    'Ex. Basic kit': 'Прим. Базовый набор',
    'No customer was selected': 'Клиент не был выбран',
    'Payout': 'Выплатить',
    'New Payout': 'Новая выплата',
    'Expenses': 'Расходы',
    'New expense': 'Новый расход',
    'Expense updated': 'Расход обновлен',
    'Expense added': 'Расход добавлен',
    'Send at appointment create': 'Отправлять при создании встречи',
    'Template successfully deleted': 'Шаблон успешно удален',
    'Successfully saved': 'Успешно сохранен',
    'Successfully removed': 'Успешно удален',
    'SMS notification is not enabled': 'СМС уведомление отключено',
    'Enable': 'Включить',
    'Bonus will not change User balance': 'Бонус не меняет баланс пользователя',
    'Bonus': 'Бонус',
    'PHONE_FORMAT': '+###(##) ###-##-##',
    'Phone format': 'Формат телефонного номера',
    'Stage': 'Этап',
    'Configurator': 'Конфигуратор',
    'Select preset': 'Выберите шаблон',
    'Movability': 'Подвижность',
    'Artificial tooth': 'Искусственный зуб',
    'Tooth missing': 'Зуб отсутствует',
    'Preview': 'Предварительный просмотр',
    'Attending doctor': 'Лечащий врач',
    'Select attending doctor': 'Выберите лечащего врача',
    'Teeth': 'Зубы',
    'Add to cart': 'Добавить в корзину',
    'Add stage': 'Добавить этап',
    'Dental plaque': 'Зубные отложения',
    'Remove stage': 'Удалить этап',
    'Added to cart': 'Добавленно в корзину',
    'Add tooth': 'Добавить зуб',
    'Group': 'Сгруппировать',
    'Add to work order': 'Добавить в наряд',
    'Save the initial examination': 'Сохранить первичный осмотр',
    'Save treatment plan': 'Сохранить план лечения',
    'Add subsistence': 'Добавить состояние',
    'Responsible': 'Ответственный',
    'Fittings': 'Примерки',
    Weak: "Слабо",
    Moderately: "Умеренно",
    Joined: "Объединенные",
    "Implant cemented": "Цементриуемые на импланте",
    Single: "Одиночные",
    "Screw fixed": "Винтовая фиксация",
    Reinforced: "Армированные",
    "System name": "Название системы",
    Diameter: "Диаметр",
    Cemented: "Цементируемые",
    'Kind of work': 'Вид работы',
    "abutment type": "тип абатмента",
    'Paint restoration': 'Окрасить реставрации',
    'no': 'нет',
    'weak': 'слабо',
    'moderately': 'умеренно',
    'Dissect teeth on the model': 'Отрепарировать зубы на модели',
    'In the missing tooth area': 'В области отсутсвующего зуба',
    'Form an ovoid impression on the model': 'Сформировать на модели овоидное вдавление',
    'joined': 'объединенные',
    'implant cemented': 'цементриуемые на импланте',
    'single': 'одиночные',
    'screw fixed': 'винтовая фиксация',
    'screw-fixed': 'винтовая фиксация',
    'reinforced': 'армированные',
    'Implant crowns': 'Коронки на имлантатах',
    'system name': 'название системы',
    'diameter': 'диаметр',
    'cemented': 'цементируемые',
    'Shoulder': 'Плечо',
    'Frame': 'Каркас',
    'Design of intermediate part': 'Дизайн промежуточной части',
    'Temporary restoration': 'Временная реставрация',
    'Select work': 'Выберите работу',
    'Work selection': 'Выбор работы',
    'Customer selection': 'Выбор пациента',
    'Next': 'Далее',
    'Append image': 'Добавить изображение',
    'New work': 'Новая работа',
    'Abutment type': 'Тип абатмента',
    'Reason for cancelling': 'Причина отмены',
    'Lab prefix': 'Префикс для нарядов',
    'Lab counter': 'Счетчик нарядов',
    'Labs': 'Лаборатория',
    'Fitted': 'Примеренный',
    'Print separately': 'Распечатать отдельно',
    'Appointments for period': 'Записей на этот период',
    'Appointments created for period': 'Записей создано за этот период',
    'Separate by color': 'Разделить по цвету',
    'y.o.': ' | {n} год | {n} года | {n} лет',
    'Preliminary treatment plan for': 'Предварительный план лечения для',
    'signature': 'подпись',
    'print plan note': 'С планом лечения ознакомлен и согласен. Мне понятен план, объем лечения, возможные осложнения на всех этапах лечения, возможность возникновения дополнительного обследования, изменение сроков лечения, дополнительной оплаты. Я получил ответы на все вопросы относительно предстоящего лечения и доверяю квалификации врачей. Цены действительны в течение 180 дней с момента проведенной консультации.',
    'remark': 'примечание',
    'full name': 'Ф.И.О.',
    'Mark as done': 'Отметить сделанным',
    'Generate and save plan': 'Сгенерировать и сохранить план лечения',
    'Elementary': 'Начальный',
    'No variations': 'Нет вариаций',
    'Treatment plan of': 'План лечения от',
    'For sending': 'К отправке',
    Wishes: 'Пожелания',
    'Shape and color': 'Форма и цвет',
    'Face shape': 'Форма лица',
    'When there is not enough space': 'При недостатке пространства',
    'The color of the crowns': 'Цвет коронки',
    'The color of the stump': 'Цвет культи',
    'The main color of the tooth': 'Основной цвет зуба',
    'The color of the gums': 'Цвет десны',
    Rectangular: 'Прямоугольная',
    Triangular: 'Треугольная',
    Oval: 'Овальная',
    'Grind the antagonist tooth': 'Пришлифовать зуб-антагонист',
    'Make a metal occlusal surface':
        'Сделать металлическую окклюзионную поверхность',
    'Completion date': 'Дата сдачи',
    Vestib: 'Вестиб',
    Without: 'Без',
    'Print an image?': 'Печатать изображение?',
    Technician: 'Техник',
    'Search technician': 'Поиск Техника',
    Main: 'Основные',
    Additional: 'Дополнительные',
    'Spoons': 'Ложки',
    'Screws': 'Винты',
    'Abutments': 'Абатменты',
    'Implants': 'Импланты',
    'Transfers': 'Трансферы',
    'Analogues': 'Аналоги',
    'Straight abutment': 'Прямой абатмент',
    'Corner abutment': 'Угловой абатмент',
    'Cast abutment': 'Отливаемый абатмент',
    'Premill': 'Премилл',
    'Temporary abutment': 'Временный абатмент',
    'Temporary abutment on multiunit': 'Временный абатмент на мультиюнит',
    'Cast abutment on multiunit': 'Отливаемый абатмент на мультиюнит',
    'Accept': 'Принять',
    'Add treatment plan': 'Добавить план лечения',
    'Preliminary treatment plan': 'Предварительный план лечения',
    'Create entry': 'Создать запись',
    'Are you sure you want to accept a job?': 'Вы уверены, что хотите принять работу?',
    'Images': 'Изображения',
    'Archive': 'Архив',
    'Material consumption for the procedure': 'Расход материала на процедуру',
    'The cost of this material for the procedure': 'Себестоимость данного материала на процедуру',
    'Number of procedures per batch': 'Кол-во процедур за партию',
    'Profit from one procedure': 'Прибыль с одной процедуры',
    'Total profit': 'Общая прибыль',
    'Total profit of sale': 'Общая сумма реализации',
    'No results found': 'Результатов не найдено',
    'No options available': 'Нет доступных опций',
    'No sub-options': 'Нет дополнительных опций',
    'notConfirmed': 'Не подтвержден',
    'Last 12 month': 'Крайние 12 месяцев',
    'Your revenue': 'Ваша прибыль',
    'Nothing found': 'Ничего не найдено',
    'Profile has been updated': 'Профиль обновлен',
    'Appointments scheduled': 'Записей сделано на период',
    'Appointments created': 'Записей создано на период',
    'conditionCodes': {
        'C': 'C',
        'Pl': 'Pl',
        'D': 'Д',
        'P': 'P',
        'Pt': 'Pt',
        'Cr': 'К',
        'AT': 'И',
        'I': 'I',
        'R': 'R',
        'M': 'П',
        'DP': 'Н',
        'TM': 'О'
    },
    'Last visit': 'Последний визит',
    'Ex.: TV Adv.': 'Например: ТВ-реклама.',
    'Searching': 'Ищу',
    'Select a team member to checkout only his treatments': 'Выберите сотрудника для оплаты его проделанной работы',
    'Your account will expire on {date}. Please contact us.':
        'Срок действия аккаунта истекает  {date}, пожалуйста свяжитесь с нами +998 99 880 09 30',
     'Your account expired. Please contact us.':"Срок действия аккаунта истек, свяжитесь с нами +998 99 880 09 30"
}
