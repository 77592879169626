export default {
    path: '/',
    component: () => import('@/Layouts/Login.vue'),
    children: [
        {path: 'login', name: 'login', component: () => import('./Login.vue')},
        {path: 'register', name: 'register', component: () => import('./Register.vue')},
        {path: 'password-reset', name: 'password-reset', component: () => import('./PasswordReset.vue')},
        {path: 'verification', name: 'verification', component: () => import('./Verification.vue'), props: true},
        {path: 'reset', name: 'verify-password-reset', component: () => import('./VerifyPasswordReset.vue'),},
    ],
    meta: {},
};
