export default {
    path: '',
    component: () => import('@/Layouts/Default.vue'),
    children: [
        { path: '/', name: 'links', component: () => import('@/Pages/Dashboard/Links.vue') },
        { path: '/themes', name: 'themes', component: () => import('@/Pages/Dashboard/Themes.vue') },
        { path: '/settings', name: 'settings', component: () => import('@/Pages/Settings.vue') },
        { path: '/statistic', name: 'statistic', component: () => import('@/Pages/Statistic.vue') },
    ],
    meta: {
        requiresAuth: true,
    }
};
